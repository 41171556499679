import React from 'react'
import Layout from '../components/layout'
import Gesicht from '../components/gesicht/gesicht'
import {CenterContainer} from "../components/basics"


const NotFoundPage = () => (
  <Layout>


<div style={{padding:"20vh"}}>

<CenterContainer>
  <Gesicht />
  </CenterContainer>

  

  <div style={{textAlign:"center"}}>

  <h1>404</h1> 
  <h2>/ NOT FOUND </h2>
  <p>What are you looking for?</p>

  </div>


</div>

  </Layout>
)

export default NotFoundPage
